"use client";

import {
  Traditional2DPlansIcon,
  TraditionalConfusedIcon,
  TraditionalLockedInIcon,
  TraditionalTimeConsumingIcon,
  YardzenContractorIcon,
  YardzenDesignTalentIcon,
  YardzenRelaxedIcon,
  YardzenRendersIcon,
} from "@yardzen/components/icons";
import { customImageLoader } from "@yardzen/next-client-util";
import { Small } from "@yardzen/ui";
import Image from "next/image";
import * as React from "react";
import { TraditionalPath } from "./TraditionalPath";

const StepIconMap: Record<string, any> = {
  YardzenDesignTalentIcon: YardzenDesignTalentIcon,
  YardzenRendersIcon: YardzenRendersIcon,
  YardzenRelaxedIcon: YardzenRelaxedIcon,
  YardzenContractorIcon: YardzenContractorIcon,
  Traditional2DPlansIcon: Traditional2DPlansIcon,
  TraditionalConfusedIcon: TraditionalConfusedIcon,
  TraditionalLockedInIcon: TraditionalLockedInIcon,
  TraditionalTimeConsumingIcon: TraditionalTimeConsumingIcon,
};

export interface Step {
  description: string;
  icon: string;
  imageAlt: string;
  iconSize: number;
  iconMobileSize: number;
}

interface ProcessStepsProps {
  steps: Step[];
  variant: "yardzen" | "traditional";
}

export const ProcessSteps: React.FC<ProcessStepsProps> = ({
  steps,
  variant,
}) => {
  const getYardzenPath = () => {
    const points = steps.map((_, index) => {
      const angle = (Math.PI / 2.2) * (index / (steps.length - 1));
      const radiusX = 50;
      const radiusY = 70;
      const centerX = 70;
      const centerY = 13;

      const x = centerX - radiusX * Math.cos(angle);
      const y = centerY + radiusY * Math.sin(angle);

      return `${x} ${y}`;
    });

    return `M ${points.join(" L ")}`;
  };

  return (
    <div
      className="border-system-grayscale-20 desktop-sm:h-[600px] desktop-sm:border-t relative h-[320px] border-dashed"
      role="list"
    >
      {variant === "yardzen" && (
        <svg
          className="desktop-sm:block absolute inset-0 hidden h-full w-full"
          preserveAspectRatio="none"
          viewBox="0 0 100 100"
        >
          <path
            d={getYardzenPath()}
            stroke="currentColor"
            className="text-brand-tertiary-80"
            strokeDasharray="1 0.5"
            strokeWidth="0.15"
            fill="none"
            opacity="0.4"
          />
        </svg>
      )}

      {variant === "traditional" && <TraditionalPath />}

      {steps.map((step, index) => {
        const angle = (Math.PI / 2.2) * (index / (steps.length - 1));
        const radiusX = 50;
        const radiusY = 70;
        const centerX = variant === "traditional" ? 15 : 75;
        const centerY = 13;

        const x =
          variant === "traditional"
            ? centerX + radiusX * Math.cos(angle)
            : centerX - radiusX * Math.cos(angle);
        const y = centerY + radiusY * Math.sin(angle);

        return (
          <div key={index}>
            <div
              className="desktop-sm:flex absolute hidden -translate-x-1/4 -translate-y-1/2 transform items-center"
              style={{
                top: `${y}%`,
                left: `${x}%`,
              }}
              role="listitem"
            >
              {variant === "traditional" && (
                <div className="-mt-4 mr-4 max-w-[12rem] self-start">
                  <Small className="text-brand-moss-greens-90 text-lg font-medium">
                    {step.description}
                  </Small>
                </div>
              )}
              <div className="bg-system-grayscale-00 relative z-10 flex h-[6.08706rem] w-[6.08706rem] shrink-0 items-center justify-center rounded-full shadow-lg">
                <Image
                  src={StepIconMap[step.icon]}
                  alt={step.imageAlt}
                  width={step.iconSize}
                  height={step.iconSize}
                  className="md:h-auto md:w-auto"
                  loading="lazy"
                  loader={customImageLoader}
                />
              </div>
              {variant === "yardzen" && (
                <div className="-mt-4 ml-4 max-w-[12rem] self-start">
                  <Small className="text-brand-primary-darker text-lg font-medium">
                    {step.description}
                  </Small>
                </div>
              )}
            </div>

            <div
              className="desktop-sm:hidden flex max-w-full items-center gap-3.5 px-4 py-2"
              role="listitem"
            >
              <div className="bg-system-grayscale-00 flex aspect-square w-[49px] shrink-0 items-center justify-center rounded-full shadow-lg">
                <Image
                  src={StepIconMap[step.icon]}
                  alt={step.imageAlt}
                  width={step.iconMobileSize}
                  height={step.iconMobileSize}
                  className="md:h-auto md:w-auto"
                  loading="lazy"
                />
              </div>
              <div className="my-auto self-stretch">
                <Small
                  className={`text-base font-medium ${
                    variant === "yardzen"
                      ? "text-brand-primary-darker"
                      : "text-brand-moss-greens-90"
                  }`}
                >
                  {step.description}
                </Small>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
